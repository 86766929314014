import React, { useEffect, useState } from 'react';
import {
  Calendar,
  Trophy,
  Code2,
  Sprout,
  ChevronDown,
  Globe,
  Users,
  Sparkles,
  Gamepad2,
  Rocket,
  Stars,
  Brain,
  Lightbulb,
  Heart,
  Timer,
} from 'lucide-react';

function Competitions() {
  const [isVisible, setIsVisible] = useState(false);
  const [timeLeft, setTimeLeft] = useState({
    days: 0,
    hours: 0,
    minutes: 0,
    seconds: 0,
  });

  useEffect(() => {
    setIsVisible(true);
  }, []);

  useEffect(() => {
    const targetDate = new Date('2025-03-28T00:00:00');

    const calculateTimeLeft = () => {
      const now = new Date();
      const difference = targetDate.getTime() - now.getTime();

      if (difference > 0) {
        setTimeLeft({
          days: Math.floor(difference / (1000 * 60 * 60 * 24)),
          hours: Math.floor((difference / (1000 * 60 * 60)) % 24),
          minutes: Math.floor((difference / 1000 / 60) % 60),
          seconds: Math.floor((difference / 1000) % 60),
        });
      }
    };

    const timer = setInterval(calculateTimeLeft, 1000);
    calculateTimeLeft(); // Initial calculation

    return () => clearInterval(timer);
  }, []);

  return (
    <div className="min-h-screen bg-gradient-to-b from-green-50 to-white">
      {/* Hero Section */}
      <header className="relative min-h-screen overflow-hidden flex items-center">
        {/* Fun Background Pattern */}
        <div
          className="absolute inset-0"
          style={{
            backgroundImage: `url('https://images.unsplash.com/photo-1550745165-9bc0b252726f?auto=format&fit=crop&q=80')`,
            backgroundSize: 'cover',
            backgroundPosition: 'center',
            filter: 'brightness(0.9)',
          }}
        ></div>
        <div className="absolute inset-0 bg-gradient-to-b from-green-500/90 to-blue-600/90"></div>

        {/* Floating Elements */}
        <div className="absolute inset-0">
          {[...Array(15)].map((_, i) => (
            <div
              key={i}
              className="absolute animate-float"
              style={{
                left: `${Math.random() * 100}%`,
                top: `${Math.random() * 100}%`,
                animationDelay: `${i * 0.3}s`,
                opacity: 0.15,
              }}
            >
              {i % 5 === 0 ? (
                <Gamepad2 className="w-16 h-16" />
              ) : i % 5 === 1 ? (
                <Rocket className="w-14 h-14" />
              ) : i % 5 === 2 ? (
                <Stars className="w-12 h-12" />
              ) : i % 5 === 3 ? (
                <Brain className="w-16 h-16" />
              ) : (
                <Lightbulb className="w-14 h-14" />
              )}
            </div>
          ))}
        </div>

        {/* Content */}
        <div className="relative container mx-auto px-4 py-12">
          <div className="max-w-5xl mx-auto text-center">
            <div
              className={`transition-all duration-1000 ${
                isVisible
                  ? 'opacity-100 translate-y-0'
                  : 'opacity-0 translate-y-10'
              }`}
            >
              {/* Fun Logo */}
              <div className="mb-4 flex justify-center">
                <img
                  src="/images/scratchlogo.png"
                  alt="SC25 Logo"
                  className="h-28"
                />
                {/* <div className="relative group cursor-pointer">
                  <div className="absolute -inset-1 rounded-full bg-gradient-to-r from-pink-400 via-purple-400 to-indigo-400 opacity-75 blur-lg group-hover:opacity-100 transition duration-200"></div>
                  <div className="relative bg-white rounded-full p-6 animate-bounce-rotate">
                    <div className="text-4xl font-bold bg-gradient-to-r from-green-400 to-blue-500 bg-clip-text text-transparent">
                      SC'25
                    </div>
                  </div>
                </div> */}
              </div>

              {/* Title */}
              <h1 className="text-6xl md:text-6xl font-bold mb-6 text-white animate-wiggle">
                Scratch Coding Competition 2025
              </h1>

              {/* Subtitle */}
              <div className="flex items-center justify-center gap-4 mb-8">
                <Sprout className="w-8 h-8 text-green-300" />
                <h2 className="text-3xl md:text-4xl font-bold bg-gradient-to-r from-green-300 via-blue-200 to-purple-300 bg-clip-text text-transparent">
                  Code for a Greener Tomorrow!
                </h2>
                <Sprout className="w-8 h-8 text-green-300" />
              </div>

              {/* Description */}
              <p className="text-xl mb-12 text-white/90 leading-relaxed max-w-3xl mx-auto">
                Hey young coders! 🚀 Ready to use your awesome coding powers to
                help save our planet? Join us for an epic adventure where YOUR
                code can make the world a better place!
              </p>

              {/* Countdown Timer */}
              <div className="mb-16 ">
                <div className="text-xl text-white mb-4 flex items-center justify-center gap-2">
                  <Timer className="w-6 h-6 animate-pulse" />
                  <span>Project Submission:</span>
                </div>
                <div className="flex justify-center gap-4">
                  {[
                    { value: timeLeft.days, label: 'Days' },
                    { value: timeLeft.hours, label: 'Hours' },
                    { value: timeLeft.minutes, label: 'Minutes' },
                    { value: timeLeft.seconds, label: 'Seconds' },
                  ].map((item, index) => (
                    <div key={index} className="relative group">
                      <div className="absolute -inset-1 bg-gradient-to-r from-pink-400 via-purple-400 to-indigo-400 rounded-xl blur opacity-75 group-hover:opacity-100 transition duration-200"></div>
                      <div className="relative bg-white px-4 py-3 rounded-xl min-w-[120px]">
                        <div className="text-4xl font-bold bg-gradient-to-r from-purple-600 to-indigo-600 bg-clip-text text-transparent">
                          {String(item.value).padStart(2, '0')}
                        </div>
                        <div className="text-sm font-medium text-gray-600">
                          {item.label}
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              </div>

              {/* CTA Button */}
            </div>
          </div>
        </div>

        {/* Fun Scroll Indicator */}
        <div className="absolute bottom-8 left-1/2 transform -translate-x-1/2 animate-bounce">
          <div className="text-white text-center flex flex-col items-center">
            <p className="mb-2 text-sm font-medium">More fun! </p>
            <ChevronDown className="w-8 h-8" />
          </div>
        </div>
      </header>

      {/* Fun Stats Section */}
      <section className="py-16 bg-gradient-to-b from-blue-600 to-purple-600 relative overflow-hidden">
        <div className="absolute inset-0">
          <div
            className="absolute inset-0"
            style={{
              backgroundImage:
                'radial-gradient(circle at 10px 10px, rgba(255, 255, 255, 0.1) 2px, transparent 0)',
              backgroundSize: '20px 20px',
            }}
          ></div>
        </div>
        <div className="container mx-auto px-4 relative">
          <div className="grid md:grid-cols-3 gap-8 max-w-5xl mx-auto">
            {[
              {
                icon: <Users className="w-10 h-10" />,
                stat: '1000+',
                label: 'Young Coders',
                color: 'from-pink-400 to-red-400',
                emoji: '👩‍💻',
              },
              {
                icon: <Globe className="w-10 h-10" />,
                stat: '50+',
                label: 'Countries',
                color: 'from-green-400 to-emerald-400',
                emoji: '🌍',
              },
              {
                icon: <Sparkles className="w-10 h-10" />,
                stat: '$10,000',
                label: 'In Prizes',
                color: 'from-yellow-400 to-orange-400',
                emoji: '🏆',
              },
            ].map((item, index) => (
              <div
                key={index}
                className="transform hover:scale-105 transition-all duration-300"
              >
                <div className="relative group">
                  <div className="absolute -inset-1 bg-gradient-to-r from-pink-400 via-purple-400 to-indigo-400 rounded-2xl blur opacity-75 group-hover:opacity-100 transition duration-200"></div>
                  <div className="relative bg-white p-8 rounded-2xl shadow-xl">
                    <div className="flex flex-col items-center text-center">
                      <div
                        className={`bg-gradient-to-r ${item.color} w-20 h-20 rounded-full flex items-center justify-center mb-4 text-white group-hover:scale-110 transition-transform`}
                      >
                        {item.icon}
                      </div>
                      <div className="text-4xl font-bold bg-gradient-to-r from-purple-600 to-indigo-600 bg-clip-text text-transparent mb-2">
                        {item.stat} {item.emoji}
                      </div>
                      <div className="text-gray-600 font-medium">
                        {item.label}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </section>
      {/* Age Categories Section */}
      <section className="py-24 bg-gradient-to-b from-purple-600 to-purple-600 relative overflow-hidden">
        {/* Coding-Themed Background */}
        <div className="absolute inset-0 overflow-hidden">
          {[...Array(30)].map((_, i) => (
            <div
              key={i}
              className="absolute animate-float text-white/20 font-mono text-lg"
              style={{
                left: `${Math.random() * 100}%`,
                top: `${Math.random() * 100}%`,
                animationDelay: `${i * 0.5}s`,
                fontSize: `${Math.random() * 16 + 12}px`,
                transform: `rotate(${Math.random() * 360}deg)`,
              }}
            >
              {i % 5 === 0 ? (
                <span>{`<code>🚀</code>`}</span>
              ) : i % 5 === 1 ? (
                <span>{`console.log("Hello World!")`}</span>
              ) : i % 5 === 2 ? (
                <span>{`if (happy) { 😊 }`}</span>
              ) : i % 5 === 3 ? (
                <span>{`<ScratchBlock />`}</span>
              ) : (
                <span>{`101010`}</span>
              )}
            </div>
          ))}
        </div>
        <div className="container mx-auto px-4 relative">
          <h2 className="text-4xl font-bold text-center mb-16 text-white animate-bounce">
            Who Can Join? 🎈
          </h2>
          <div className="grid md:grid-cols-2 lg:grid-cols-4 gap-8 max-w-5xl mx-auto">
            {[
              {
                age: '7-8 Years',
                emoji: '👶',
                color: 'from-yellow-400 to-orange-400',
                icon: '🎨',
              },
              {
                age: '9-10 Years',
                emoji: '🧒',
                color: 'from-green-400 to-emerald-400',
                icon: '🚀',
              },
              {
                age: '11-12 Years',
                emoji: '👦',
                color: 'from-blue-400 to-indigo-400',
                icon: '🛸',
              },
              {
                age: '13-14 Years',
                emoji: '👧',
                color: 'from-pink-400 to-purple-400',
                icon: '🌟',
              },
            ].map((item, index) => (
              <div
                key={index}
                className="transform hover:scale-105 transition-all duration-300 hover:rotate-2"
              >
                <div className="relative group">
                  <div className="absolute -inset-1 bg-gradient-to-r from-pink-400 via-purple-400 to-indigo-400 rounded-2xl blur opacity-75 group-hover:opacity-100 transition duration-200"></div>
                  <div className="relative bg-white p-8 rounded-2xl shadow-xl hover:shadow-2xl transition-shadow">
                    <div className="flex flex-col items-center text-center">
                      {/* Fun Emoji Background */}
                      <div
                        className={`bg-gradient-to-r ${item.color} w-24 h-24 rounded-full flex items-center justify-center mb-6 text-white group-hover:animate-wiggle`}
                      >
                        <span className="text-6xl">{item.emoji}</span>
                      </div>
                      {/* Age Group Title */}
                      <h3 className="text-2xl font-bold bg-gradient-to-r from-purple-600 to-indigo-600 bg-clip-text text-transparent mb-2">
                        {item.age}
                      </h3>
                      {/* Fun Icon */}
                      <div className="text-4xl animate-bounce">{item.icon}</div>
                      {/* Call to Action */}
                      <p className="mt-4 text-sm text-gray-600 font-medium">
                        Ready to code? Let's go! 🎉
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </section>

      {/* Timeline Section */}
      <section className="py-24 bg-gradient-to-b from-purple-600 to-pink-600 relative overflow-hidden">
        <div className="container mx-auto px-4 relative">
          <h2 className="text-4xl font-bold text-center mb-16 text-white">
            Your Coding Adventure Timeline! 🚀
          </h2>
          <div className="grid md:grid-cols-3 gap-8 max-w-5xl mx-auto">
            {[
              {
                icon: <Calendar />,
                title: 'Theme Reveal',
                date: 'February 10, 2025',
                color: 'from-yellow-400 to-orange-400',
              },
              {
                icon: <Code2 />,
                title: 'Coding Time!',
                date: 'March 01-28, 2025',
                color: 'from-green-400 to-emerald-400',
              },
              {
                icon: <Trophy />,
                title: 'Winners Party!',
                date: 'April 4, 2025',
                color: 'from-pink-400 to-purple-400',
              },
            ].map((item, index) => (
              <div
                key={index}
                className="transform hover:scale-105 transition-all duration-300"
              >
                <div className="relative group">
                  <div className="absolute -inset-1 bg-gradient-to-r from-pink-400 via-purple-400 to-indigo-400 rounded-2xl blur opacity-75 group-hover:opacity-100 transition duration-200"></div>
                  <div className="relative bg-white p-8 rounded-2xl shadow-xl">
                    <div
                      className={`bg-gradient-to-r ${item.color} w-16 h-16 rounded-full flex items-center justify-center mb-6 mx-auto text-white`}
                    >
                      {item.icon}
                    </div>
                    <h3 className="text-2xl font-bold mb-3 text-center bg-gradient-to-r from-purple-600 to-indigo-600 bg-clip-text text-transparent">
                      {item.title}
                    </h3>
                    <p className="text-center text-gray-600 font-medium">
                      {item.date}
                    </p>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </section>

      {/* Rules Section */}
      <section className="py-24 bg-gradient-to-b from-pink-600 to-red-600 relative overflow-hidden">
        <div className="container mx-auto px-4 relative">
          <h2 className="text-4xl font-bold text-center mb-16 text-white">
            Game Rules! 🎮
          </h2>
          <div className="max-w-5xl mx-auto grid md:grid-cols-2 gap-12">
            {[
              {
                title: 'How to Win Points! 🌟',
                items: [
                  'Cool theme ideas',
                  'Awesome story and gameplay',
                  'Amazing graphics and sounds',
                  'Super clean code',
                  'WOW factor!',
                ],
              },
              {
                title: 'Important Stuff to Remember! 📝',
                items: [
                  'Solo adventure - no teams',
                  'Use Scratch 3.0',
                  'No extra add-ons',
                  'Make it yourself',
                  'Create during contest time',
                ],
              },
            ].map((section, index) => (
              <div
                key={index}
                className="transform hover:scale-105 transition-all duration-300"
              >
                <div className="relative group">
                  <div className="absolute -inset-1 bg-gradient-to-r from-pink-400 via-purple-400 to-indigo-400 rounded-2xl blur opacity-75 group-hover:opacity-100 transition duration-200"></div>
                  <div className="relative bg-white p-8 rounded-2xl shadow-xl">
                    <h3 className="text-2xl font-bold mb-6 text-center bg-gradient-to-r from-purple-600 to-indigo-600 bg-clip-text text-transparent">
                      {section.title}
                    </h3>
                    <ul className="space-y-4">
                      {section.items.map((item, i) => (
                        <li key={i} className="flex items-center gap-3 group">
                          <div className="w-3 h-3 bg-gradient-to-r from-pink-400 to-purple-400 rounded-full group-hover:scale-125 transition-transform"></div>
                          <span className="text-gray-700 group-hover:text-purple-600 transition-colors font-medium">
                            {item}
                          </span>
                        </li>
                      ))}
                    </ul>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </section>

      {/* Fun Footer */}
      <footer className="bg-gradient-to-b from-red-600 to-pink-600 text-white py-16 relative overflow-hidden">
        <div className="absolute inset-0">
          <div
            className="absolute inset-0"
            style={{
              backgroundImage:
                'radial-gradient(circle at center, rgba(255,255,255,0.2) 0%, transparent 2%)',
              backgroundSize: '3rem 3rem',
            }}
          ></div>
        </div>
        <div className="container mx-auto px-4 relative">
          <div className="text-center">
            <div className="flex justify-center items-center gap-4 mb-8">
              <Heart className="w-8 h-8 text-pink-300 animate-pulse" />
              <h2 className="text-3xl font-bold">
                Ready to Code and Save the Planet?
              </h2>
              <Heart className="w-8 h-8 text-pink-300 animate-pulse" />
            </div>
            <p className="text-xl mb-8 text-pink-200">
              Let's make the world better, one block of code at a time! 🌍
            </p>
            <div className="flex justify-center gap-4">
              {[Rocket, Stars, Brain, Lightbulb].map((Icon, index) => (
                <div
                  key={index}
                  className="animate-bounce-rotate"
                  style={{ animationDelay: `${index * 0.2}s` }}
                >
                  <Icon className="w-8 h-8 text-pink-300" />
                </div>
              ))}
            </div>
          </div>
        </div>
      </footer>
    </div>
  );
}

export default Competitions;
